import React, { useState, useEffect } from 'react';

const PolicyBanner = () => {
  const [bannerHidden, setBannerHidden] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the banner has been closed before
    const isBannerClosed = localStorage.getItem('bannerClosed');
    if (isBannerClosed) {
      setBannerHidden(true);
    }
  }, []);

  const handleBannerExit = () => {
    setBannerHidden(true);
    localStorage.setItem('bannerClosed', 'true'); // Store banner closed state
  };

  return (
    !bannerHidden && (
      <div className="policy-banner">
        <div className="policy-banner_text">
          <p className="policy-banner_p">
            Mit der Nutzung des Tools für interaktive onkologische Patientenfälle willigen Sie ein, dass Ihre personenbezogenen Daten (u.a. Customer-ID und Ihre Auswahlmöglichkeiten) gemäß der unten verlinkten Datenschutzerklärung verarbeitet werden. Die Nutzung ist freiwillig. Wenn Sie diese Verarbeitung nicht wünschen, schließen Sie diese Webseite.
          </p>
          <p className="policy-banner_p">
            Sie können Ihre Einwilligung unter <a href="mailto:werbewiderspruch@lilly.com">werbewiderspruch@lilly.com</a> widerrufen.<br />
            Nähere Informationen zu Ihren Datenschutzrechten finden Sie in der <a href="https://patientcases-assets.s3.eu-central-1.amazonaws.com/Bundl+Privacy+Notice.pdf" target="_blank">Datenschutzerklärung</a>.
          </p>
        </div>
        <img
          src="https://cdn.prod.website-files.com/65d31f0a22c6c026b005c6b9/65d34b84bb2af72adfc0a54f_exit-btn.svg"
          alt="Exit Button"
          className="policy-banner_exit"
          onClick={handleBannerExit}
        />
      </div>
    )
  );
};

export default PolicyBanner;
