// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #1A8693;
    color: white;
    padding: 2rem 1rem;
    text-align: center;
    margin-top: 30px;
}

.footer-container {
    max-width: 1560px;
    padding: 0px 40px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.footer-brand {
    flex: 1 1;
    font-size: 0.9rem;
    text-align: left;
}

.footer-links {
    flex: 1 1;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footer-links a {
    color: white;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s;
}

.footer-links a:hover {
    opacity: .7;
}

.footer-pp {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

@media (max-width: 768px) {
    .footer {
        margin-top: 0;
    }

    .footer-container {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }

    .footer-brand,
    .footer-links,
    .footer-social {
        flex: unset;
        text-align: center;
    }

    .footer-links {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/homeComponents/Footer/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,SAAO;IACP,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAO;IACP,aAAa;IACb,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,sBAAsB;QACtB,mBAAmB;QACnB,WAAW;IACf;;IAEA;;;QAGI,WAAW;QACX,kBAAkB;IACtB;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".footer {\n    background-color: #1A8693;\n    color: white;\n    padding: 2rem 1rem;\n    text-align: center;\n    margin-top: 30px;\n}\n\n.footer-container {\n    max-width: 1560px;\n    padding: 0px 40px;\n    margin: 0 auto;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    align-items: center;\n    gap: 1rem;\n}\n\n.footer-brand {\n    flex: 1;\n    font-size: 0.9rem;\n    text-align: left;\n}\n\n.footer-links {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    gap: 1rem;\n}\n\n.footer-links a {\n    color: white;\n    text-decoration: none;\n    font-size: 0.9rem;\n    transition: color 0.3s;\n}\n\n.footer-links a:hover {\n    opacity: .7;\n}\n\n.footer-pp {\n    flex: 1;\n    display: flex;\n    justify-content: flex-end;\n    gap: 1rem;\n}\n\n@media (max-width: 768px) {\n    .footer {\n        margin-top: 0;\n    }\n\n    .footer-container {\n        flex-direction: column;\n        align-items: center;\n        gap: 1.5rem;\n    }\n\n    .footer-brand,\n    .footer-links,\n    .footer-social {\n        flex: unset;\n        text-align: center;\n    }\n\n    .footer-links {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
