function generateRandomId() {
    const min = Math.pow(10, 9);
    const max = Math.pow(10, 10) - 1;
    const randomId = Math.floor(Math.random() * (max - min + 1)) + min;
    // console.log("id generated", randomId)
    return randomId.toString();
}

function setRandomId() {
    let storedRandomId = localStorage.getItem("random_id")

    if (!storedRandomId) {
        const randomId = generateRandomId();
        console.log("Generated Random ID:", randomId);
        localStorage.setItem("random_id", randomId);
        storedRandomId = randomId;
    }
}

export default setRandomId;