import React from "react";
import { useQuery, gql } from "@apollo/client";
import Header from "../homeComponents/Header";
import HeroSection from "../homeComponents/HeroSection";
import CasesSection from "../homeComponents/CasesSection";
import Footer from "../homeComponents/Footer";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";

import "./HomePage.css";
import FeaturesOverview from "../homeComponents/FeaturesOverview";
import placeholderCase1 from "../../src/assets/placeholders/placeholder-scan.png";
import placeholderCase2 from "../../src/assets/placeholders/placeholder-hematology.png";

// GraphQL query to fetch data for the landing page
const LANDING_PAGE_QUERY = gql`
  query LandingPage {
    landingPage {
      data {
        attributes {
          Hero {
            Title
            Tagline
            Button
            HCPs {
              Text
              doctors {
                data {
                  attributes {
                    Name
                    ProfilePicture {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          CasesCarousel {
            cases {
              data {
                attributes {
                  Title
                  Category
                  Slug
                  Image {
                    data {
                      attributes {
                        url 
                      }
                    }
                  }
                  Doctor {
                    data {
                      attributes {
                        Name
                        Description
                        ProfilePicture {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          FeatureHighlight {
            Title
            Description
            Image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          CasesSection {
            Title
            Description
            Filters
            cases {
              data {
                attributes {
                  Title
                  Category
                  Slug
                  Image {
                    data {
                      attributes {
                        url 
                      }
                    }
                  }
                  Doctor {
                    data {
                      attributes {
                        Name
                        Description
                        ProfilePicture {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          Footer {
            Title
            PpNumber
            FooterLinks {
              Name
              Url
            }
          }
          FeaturesSection {
            Title
            Description
            Feature {
              Title
              Description
              Image {
                data {
                  attributes {
                    url 
                  }
                }
              }
            }
          }
          MetaData {
            MetaImage {
              data {
                attributes {
                  url
                }
              }
            }
            MetaDescription
            MetaTitle
          }
        }
      }
    }
  }
`;

const HomePageV2 = () => {
  const { data, loading, error } = useQuery(LANDING_PAGE_QUERY);

  // Handle loading and error states
  if (loading) return null;
  if (error) return <div>Error: {error.message}</div>;

  // Extracting data
  const {
    Hero: {
      Title: heroTitle,
      Tagline: heroTagline,
      Button: heroButton,
      HCPs: { Text: hcpText, doctors: hcpDoctors },
    },
    CasesCarousel: { cases: casesCarouselCases },
    FeatureHighlight: {
      Title: featureHighlightTitle,
      Description: featureHighlightDescription,
      Image
    },
    CasesSection: {
      Title: casesSectionTitle,
      Description: casesSectionDescription,
      Filters: casesSectionFilters,
      cases: casesSectonCases
    },
    FeaturesSection: {
      Title: featuresSectionTitle,
      Description: featuresSectionDescription,
      Feature: featuresArray
    },
    Footer: {
      Title: footerTitle,
      PpNumber: ppNumber,
      FooterLinks: footerLinks
    },
    // MetaData: {},

  } = data.landingPage.data.attributes;

  const { MetaData = {} } = data.landingPage.data.attributes || {};

  const {
    MetaTitle: metaTitle = "Was machst du, Kollege:in?",
    MetaDescription: metaDescription = "Angewandtes Know-how,blicken Sie Ihren Kolleg:innen über die Schulter",
    MetaImage: MetaImage = { data: { attributes: { url: "" } } }
  } = MetaData || {};

  const featureHighlightImage = Image?.data?.attributes?.url || "";
  const MetaImageUrl = MetaImage?.data?.attributes?.url || "";

  const formattedFooterLinks = footerLinks.map((link) => ({
    name: link.Name,
    url: link.Url,
  }));

  // Map the cases
  const casesCarouselData = (casesCarouselCases?.data || []).map((caseItem) => {
    const attributes = caseItem?.attributes || {};
    const doctor = attributes?.Doctor?.data?.attributes || {};
    const profilePicture = doctor?.ProfilePicture?.data?.attributes || {};
    return {
      title: attributes.Title || "Untitled",
      slug: attributes.Slug || "",
      doctorName: doctor.Name || "Unknown Doctor",
      doctorDescription: doctor.Description || "No description available",
      doctorProfile: profilePicture.url || placeholderCase1,
      image: attributes.Image?.data?.attributes?.url || profilePicture.url || placeholderCase1,
      category: attributes.Category || "Unknown Category"
    };
  });

  const casesSectionData = (casesSectonCases?.data || []).map((caseItem) => {
    const attributes = caseItem?.attributes || {};
    const doctor = attributes?.Doctor?.data?.attributes || {};
    const profilePicture = doctor?.ProfilePicture?.data?.attributes || {};
    return {
      title: attributes.Title || "Untitled",
      slug: attributes.Slug || "",
      doctorName: doctor.Name || "Unknown Doctor",
      doctorDescription: doctor.Description || "No description available",
      doctorProfile: profilePicture.url || placeholderCase1,
      image: attributes.Image?.data.attributes.url || profilePicture.url || placeholderCase1,
      category: attributes.Category || "Unknown Category"
    };
  });


  const features = (featuresArray || []).map((feature) => ({
    title: feature.Title.replace(/<\/?p>/g, ""), // Remove <p> tags
    description: feature.Description.replace(/<\/?p>/g, ""), // Remove <p> tags
    image: feature.Image.data.attributes.url
  }));

  console.log("Mapped Features:", features); // Debugging

  console.log('metaTitle', metaTitle); // Check if it's null or undefined


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }} // Fast fade-in transition
      className="homepage"
    >
      {/* <div className="homepage"> */}
      <Helmet>
        <title>{(metaTitle || "").replace(/<\/?p>/g, "")}</title>
        <meta name="description" content={(metaDescription || "").replace(/<\/?p>/g, "")} />

        {/* Open Graph Meta Tags (for Facebook, LinkedIn, WhatsApp) */}
        <meta property="og:title" content={(metaTitle || "").replace(/<\/?p>/g, "")} />
        <meta property="og:description" content={(metaDescription || "").replace(/<\/?p>/g, "")} />
        {MetaImageUrl && <meta property="og:image" content={MetaImageUrl} />}
        <meta property="og:url" content="https://www.wasmachstdukollegein.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={(metaTitle || "").replace(/<\/?p>/g, "")} />
        <meta name="twitter:description" content={(metaDescription || "").replace(/<\/?p>/g, "")} />
        {MetaImageUrl && <meta name="twitter:image" content={MetaImageUrl} />}
      </Helmet>

      <HeroSection
        title={heroTitle}
        tagline={heroTagline}
        buttonText={heroButton}
        hcpText={hcpText}
        hcpDoctors={hcpDoctors}
        cases={casesCarouselData}
        featureHighlight={{ featureHighlightTitle, featureHighlightDescription, featureHighlightImage }}
      />
      <CasesSection
        title={casesSectionTitle}
        description={casesSectionDescription}
        filters={casesSectionFilters}
        cases={casesSectionData}
      />
      {/* <FeaturesOverview
        title={featuresSectionTitle}
        description={featuresSectionDescription}
        features={features}
      /> */}
      <Footer title={footerTitle} ppNumber={ppNumber} links={formattedFooterLinks} />
      {/* </div> */}
    </motion.div>
  );
};

export default HomePageV2;