import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";
import Slider from "react-slick";
import MySlider, { Slide } from "../CaseCarousel";
import CaseCarousel from "../CaseCarousel";
import doctorPlaceholder from '../../assets/placeholders/placeholder-doctor.png'
import fullStar from '../../assets/images/star-white_filled.svg';
import halfStar from '../../assets/images/star-white_half.svg.svg'
import FeatureHighlight from "../FeatureHighlight";

// import { Navigation, Pagination } from "swiper";

const HeroSection = ({ title, tagline, data, cases, featureHighlight, hcpDoctors }) => {
    hcpDoctors.data.map((hcp, index) => (
        console.log(`hcpDoctors ${index}`, hcp.attributes.ProfilePicture.data.attributes.url)

    ))
    
    return (
        <section className="heroSection">
            <div className="hero">
                {/* Left Content */}
                <div className="hero-left">
                    <img
                        className="hero-logo"
                        src="https://cdn.prod.website-files.com/65d31f0a22c6c026b005c6b9/660ffba5680732599ba6c992_lilly-kollege-in.svg"
                        alt="Logo"
                    />

                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    <p className="description" dangerouslySetInnerHTML={{ __html: tagline }} />

                    <button
                        className="button buttonNew"
                        style={{ justifySelf: 'flex-end' }}
                        onClick={() => document.getElementById('cases-section').scrollIntoView({ behavior: 'smooth' })}
                    >

                        Jetzt mitmachen

                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_980_143)">
                                <rect x="0.313477" y="0.612961" width="25" height="25" rx="12.5" fill="white" />
                                <path d="M17.4863 9.60796C17.2711 9.60796 17.0968 9.78223 17.0968 9.99741V12.6311L13.0422 9.68248C12.9234 9.59631 12.7666 9.58415 12.6362 9.65035C12.5057 9.71705 12.4234 9.85092 12.4234 9.99746V12.6317L8.36825 9.68253C8.24996 9.59636 8.09272 9.58419 7.96225 9.6504C7.83226 9.71709 7.75 9.85097 7.75 9.99751V16.2288C7.75 16.3753 7.83227 16.5092 7.96273 16.5759C8.01872 16.6041 8.07908 16.6182 8.13945 16.6182C8.22026 16.6182 8.30059 16.5929 8.36825 16.5437L12.4234 13.5946V16.2288C12.4234 16.3753 12.5057 16.5092 12.6362 16.5759C12.6921 16.6041 12.7525 16.6183 12.8129 16.6183C12.8937 16.6183 12.974 16.5929 13.0422 16.5438L17.0968 13.5947V16.2288C17.0968 16.444 17.2711 16.6183 17.4863 16.6183C17.7015 16.6183 17.8757 16.444 17.8757 16.2288V9.9976C17.8757 9.78242 17.7015 9.60815 17.4863 9.60815V9.60796Z" fill="#1A8693" />
                            </g>
                            <defs>
                                <clipPath id="clip0_980_143">
                                    <rect x="0.313477" y="0.612961" width="25" height="25" rx="12.5" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </button>
                </div>

                {/* Right Content */}
                <div className="hero-right">
                    <div className="hcp-card">
                        {/* <p>Treatment Cases by <span style={{fontWeight: '500'}}>Renowned HCP's</span></p> */}
                        <p><span style={{ fontWeight: '500' }}>Renommierte Expert:innen</span><br />teilen Ihre Patientenfälle</p>

                        <div className="hcp-images">
                            {hcpDoctors.data.map((hcp, index) => (
                                <div className="hcp-image-container" key={index}>
                                    <img src={hcp.attributes.ProfilePicture.data.attributes.url} alt={`HCP ${index + 1}`} />
                                    <span className="hcp-name">{hcp.attributes.Name}</span>
                                </div>
                            ))}
                        </div>

                    </div>
                    {/* <div className="hcp-rating">
                        <div className="rating-stars">
                            <img src={fullStar} />
                            <img src={fullStar} />
                            <img src={fullStar} />
                            <img src={fullStar} />
                            <img src={halfStar} />
                        </div>
                        <span>4.7/5 by HCPs*</span>
                    </div> */}
                </div>
            </div>

            <div className="case-carousel">
                <CaseCarousel cases={cases} />
            </div>

            <div className="doctors-highlight">
                <FeatureHighlight title={featureHighlight.featureHighlightTitle} description={featureHighlight.featureHighlightDescription} image={featureHighlight.featureHighlightImage} />
            </div>
        </section>
    );
};

export default HeroSection;
