import { useEffect, useRef, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import i18n from "./i18n";

import saveUTMsToLocalStorage from './utils/saveUTMs';
import userTrackingFS from './utils/userTrackingFS';
import setRandomId from "./utils/setRandomId";
import LanguageSelector from './components/LanguageSelector';
import PolicyBanner from "./components/PolicyBanner";
import { UnlockedChaptersProvider } from "./utils/UnlockedCases";

import './App.css';

const Homepage = lazy(() => import('./pages/Homepage'));
const Case = lazy(() => import('./pages/Case'));

// console.log('API URL:', process.env.REACT_APP_API_URL); // Debug line

// Apollo Client Setup with memory optimization
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          largeDataField: {
            merge: false,
          },
        },
      },
    },
  }),
  request: (operation) => {
    const currentLanguage = i18n.language;
    operation.setContext({
      headers: {
        'Accept-Language': currentLanguage,
      },
    });
  },
});

// Custom Hook to control scroll behavior based on route
const useNoScrollOnCasePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/cases/")) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [location]);
};

// Function to check for 'hcp_id' in URL and localStorage
const hasHcpId = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has('hcp_id') || localStorage.getItem('hcp_id');
};

// Scroll Handler Component that uses the custom hook
function CaseScrollHandler({ children }) {
  useNoScrollOnCasePage();
  return <>{children}</>;
}

const App = () => {
  const { i18n } = useTranslation();
  const [pageloadSubmitted, setPageload] = useState(false);

  saveUTMsToLocalStorage(); // Track UTMs in local storage

  const isInitialRender = useRef(true);

  // Track page load event
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!pageloadSubmitted) {
      setPageload(true);
      setRandomId('pageLoaded');
      userTrackingFS('pageLoaded');
    }
  }, [pageloadSubmitted]);

  return (
    <Router>
      <ApolloProvider client={client}>
        <UnlockedChaptersProvider>
          <CaseScrollHandler>
            <div className="App">
              <LanguageSelector i18n={i18n} />
              {hasHcpId() && <PolicyBanner />} {/* Conditionally render PolicyBanner */}

              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/cases/:slug" element={<Case />} />
                </Routes>
              </Suspense>
            </div>
          </CaseScrollHandler>
        </UnlockedChaptersProvider>
      </ApolloProvider>
    </Router>
  );
};

export default App;