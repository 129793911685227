import React from "react";
import "./style.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        {/* Logo */}
        <div className="logo">
          <a href="/">
            <img src="https://via.placeholder.com/150x50" alt="Logo" />
          </a>
        </div>

        {/* Navigation */}
        <nav className="nav">
          <ul className="nav-list">
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#cases">Cases</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <button className="cta-button">Sign Up</button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;