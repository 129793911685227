import React, { useState } from "react";
import { motion } from "framer-motion";
import "./style.css";

const FeatureHighlight = ({title, description, image}) => {
  console.log('image', image)
  return (
    <div className="doctors-highlight-container">
      <div className="text-section">
        <h2 className="h2" dangerouslySetInnerHTML={{ __html: title }}/>
        <p className="description" dangerouslySetInnerHTML={{ __html: description }}/>
      </div>

      <div className="images-section">
        <img src={image} />
      </div>
    </div>
  );
};

export default FeatureHighlight;
