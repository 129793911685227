import React, { useRef, useEffect } from "react";
import "./style.css";
import feature1 from "../../assets/placeholders/placeholder-feature1.png";
import feature2 from "../../assets/placeholders/placeholder-feature2.png";

const FeaturesOverview = ({title, description, features}) => {
    const scrollRef = useRef(null);
    const thumbRef = useRef(null);
    console.log('features', features)

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        const thumb = thumbRef.current;

        const updateThumbPosition = () => {
            if (!scrollContainer || !thumb) return;

            const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
            const scrollRatio = scrollLeft / (scrollWidth - clientWidth);

            // Calculate thumb position
            const thumbMaxTravel = 100 - 30; // Track width - thumb width
            const thumbPosition = thumbMaxTravel * scrollRatio;

            thumb.style.transform = `translateX(${thumbPosition}px)`;
        };

        scrollContainer.addEventListener("scroll", updateThumbPosition);

        // Initialize thumb position
        updateThumbPosition();

        return () => {
            scrollContainer.removeEventListener("scroll", updateThumbPosition);
        };
    }, []);

    const scrollLeft = () => {
        scrollRef.current.scrollBy({ left: -400, behavior: "smooth" });
    };

    const scrollRight = () => {
        scrollRef.current.scrollBy({ left: 400, behavior: "smooth" });
    };

    return (
        <div className="cases-container">
            <section className="features-section">
                <div className="features-header">
                    <h2 className="h2" dangerouslySetInnerHTML={{__html: title}}/>
                    <p className="description" dangerouslySetInnerHTML={{__html: description}}/>
                </div>

                <div className="features-container">
                    <button
                        className="scroll-button left"
                        onClick={scrollLeft}
                        aria-label="Scroll left"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M12.3511 14.7017L7.64941 10L12.3511 5.29834" stroke="black" strokeWidth="1.56722" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <div className="features" ref={scrollRef}>
                        {features.map((feature, index) => (
                            <div className="feature-card" key={index}>
                                <img
                                    src={feature.image}
                                    alt={feature.Title}
                                    className="feature-image"
                                />
                                <div>
                                    <h3 className="feature-title" dangerouslySetInnerHTML={{ __html: feature.title }}/>
                                    <p className="feature-description" dangerouslySetInnerHTML={{ __html: feature.description }}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="scroll-thumb" ref={thumbRef}></div>
                    <button
                        className="scroll-button right"
                        onClick={scrollRight}
                        aria-label="Scroll right"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.64893 5.29834L12.3506 10L7.64893 14.7017" stroke="black" strokeWidth="1.56722" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </section>
        </div>
    );
};

export default FeaturesOverview;
