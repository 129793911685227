var clickedBtnArr = [];

function userTrackingFS(trackItem) {
    clickedBtnArr.push(trackItem);
    // console.log('submitting', clickedBtnArr.toString())

    const formData = new FormData();
    formData.append('clickedButtons', clickedBtnArr.toString());
    formData.append("utm_source", localStorage.getItem("par_source"));
    formData.append("utm_medium", localStorage.getItem("par_medium"));
    formData.append("utm_campaign", localStorage.getItem("par_campaign"));
    formData.append("utm_content", localStorage.getItem("par_content"));
    formData.append("random_id", localStorage.getItem("random_id"));
    formData.append("hcp_id", localStorage.getItem("hcp_id"));

    fetch('https://formspree.io/f/xpwaaory', {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
    });
}

export default userTrackingFS;