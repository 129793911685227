import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LanguageSelector = ({ i18n }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="languageSelector" style={{display: 'none'}}>
      <div className="languageSelectorHeader" onClick={toggleDropdown}>
        {selectedLanguage === 'de' ? 'German (DE)' : 'Austrian German (DE-AT)'}
        <span className={`languageSelectorHeaderArrow ${dropdownOpen ? 'open' : ''}`}>▼</span>
      </div>
      {dropdownOpen && (
        <div className="languageSelectorHeaderList">
          <div className="languageSelectorHeaderItem" onClick={() => changeLanguage('de')}>
            German (DE)
          </div>
          <div className="languageSelectorHeaderItem" onClick={() => changeLanguage('de-AT')}>
            Austrian German (DE-AT)
          </div>
        </div>
      )}
    </div>
  );
};

LanguageSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default LanguageSelector;
