import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const CaseCard = ({
  title,
  category,
  doctorName,
  doctorDescription,
  doctorProfile,
  image,
  slug,
  categoryIcon,
}) => {
  return (
    <div className="case-card">
      <div
        className="case-thumbnail"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 154.15%),url('${image}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="case-category">
          {categoryIcon && <img className="category-icon" src={categoryIcon} alt={category} />}
          {category}
        </div>
        <div className="doctor-block">
          <img src={doctorProfile} className="doctor-image" alt={doctorName} />
          <div className="doctor-description">
            <p className="doctor-name">{doctorName}</p>
            <p className="case-doctorDescription">{doctorDescription}</p>
          </div>
        </div>
      </div>
      <div className="case-content">
        <div className="case-duration">10 Minuten</div>
        <h3 className="case-title__small" dangerouslySetInnerHTML={{ __html: title }} />
        <Link to={`/cases/${slug}`} className="button buttonNew" style={{ justifySelf: "flex-end" }}>
          Jetzt mitmachen
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_980_143)">
              <rect x="0.313477" y="0.612961" width="25" height="25" rx="12.5" fill="white" />
              <path
                d="M17.4863 9.60796C17.2711 9.60796 17.0968 9.78223 17.0968 9.99741V12.6311L13.0422 9.68248C12.9234 9.59631 12.7666 9.58415 12.6362 9.65035C12.5057 9.71705 12.4234 9.85092 12.4234 9.99746V12.6317L8.36825 9.68253C8.24996 9.59636 8.09272 9.58419 7.96225 9.6504C7.83226 9.71709 7.75 9.85097 7.75 9.99751V16.2288C7.75 16.3753 7.83227 16.5092 7.96273 16.5759C8.01872 16.6041 8.07908 16.6182 8.13945 16.6182C8.22026 16.6182 8.30059 16.5929 8.36825 16.5437L12.4234 13.5946V16.2288C12.4234 16.3753 12.5057 16.5092 12.6362 16.5759C12.6921 16.6041 12.7525 16.6183 12.8129 16.6183C12.8937 16.6183 12.974 16.5929 13.0422 16.5438L17.0968 13.5947V16.2288C17.0968 16.444 17.2711 16.6183 17.4863 16.6183C17.7015 16.6183 17.8757 16.444 17.8757 16.2288V9.9976C17.8757 9.78242 17.7015 9.60815 17.4863 9.60815V9.60796Z"
                fill="#1A8693"
              />
            </g>
            <defs>
              <clipPath id="clip0_980_143">
                <rect x="0.313477" y="0.612961" width="25" height="25" rx="12.5" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default CaseCard;