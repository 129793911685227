import React, { useState, useEffect } from "react";
import "./style.css";
import CaseCard from "../CaseCard";
import iconOncology from "../../assets/icons/oncology.svg";
import iconOncologyActive from "../../assets/icons/oncology-active.svg";
import iconObesity from "../../assets/icons/obesity.svg";
import iconObesityActive from "../../assets/icons/obesity-active.svg";
import iconHematology from "../../assets/icons/hematology.svg";
import iconHematologyActive from "../../assets/icons/hematology-active.svg";
import iconDermatology from "../../assets/icons/dermatology.svg";
import iconDermatologyActive from "../../assets/icons/dermatology-active.svg";
import iconDiabetes from "../../assets/icons/diabetes.svg";
import iconDiabetesActive from "../../assets/icons/diabetes-active.svg";

// Normalized iconMapping
const iconMapping = {
  onkologie: { icon: iconOncology, iconActive: iconOncologyActive },
  hämatologie: { icon: iconHematology, iconActive: iconHematologyActive },
  adipositas: { icon: iconObesity, iconActive: iconObesityActive },
  diabetes: { icon: iconDiabetes, iconActive: iconDiabetesActive },
  dermatologie: { icon: iconDermatology, iconActive: iconDermatologyActive },
};

// Map Strapi categories to their intended display names
const categoryMapping = {
  Haematologie: "Hämatologie",
  hamatologie: "Hämatologie",
  Onkologie: "Onkologie",
  Adipositas: "Adipositas",
  Diabetes: "Diabetes",
  Dermatologie: "Dermatologie",
};

const normalizeCategory = (category) => {
  const categoryMapping = {
    Haematologie: "Hämatologie",
    hamatologie: "Hämatologie",
    Onkologie: "Onkologie",
    Adipositas: "Adipositas",
    Diabetes: "Diabetes",
    Dermatologie: "Dermatologie",
  };

  return categoryMapping[category] || category; // Normalize the category
};

const normalizeUtmContent = (utmContent) => {
  const utmMapping = {
    hamatologie: "Hämatologie",
    onkologie: "Onkologie",
    adipositas: "Adipositas",
    diabetes: "Diabetes",
    dermatologie: "Dermatologie",
  };

  const matchedUtm = Object.keys(utmMapping).find((key) =>
    utmContent.toLowerCase().includes(key)
  );
  return utmMapping[matchedUtm] || utmContent; // Default to input if no match
};

const CasesSection = ({ title, description, filters, cases }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const utmContentParam = params.get("utm_content");

    if (utmContentParam) {
      const normalizedUtmContent = normalizeUtmContent(utmContentParam); // Normalize the utm_content
      const matchingFilter = filters.find(
        (filter) => normalizeCategory(filter) === normalizedUtmContent
      );

      if (matchingFilter) {
        setSelectedCategory(normalizeCategory(matchingFilter));
      }
    }
  }, [filters]);

  // Prepare categories
  const categories = filters.map((filter) => {
    const normalizedFilter = normalizeCategory(filter);
    return {
      name: normalizedFilter,
      icon: iconMapping[normalizedFilter.toLowerCase()]?.icon,
      iconActive: iconMapping[normalizedFilter.toLowerCase()]?.iconActive,
      disabled: !iconMapping[normalizedFilter.toLowerCase()],
    };
  });

  // Filter cases based on the selected category
  const filteredCases = cases.filter(
    (caseItem) =>
      !selectedCategory ||
      normalizeCategory(caseItem.category) === selectedCategory
  );

  const visibleCases = showAll
    ? filteredCases
    : filteredCases.length > 11
      ? filteredCases.slice(0, 5)
      : filteredCases;

  const placeholders = Array.from(
    { length: (3 - (visibleCases.length % 3)) % 3 },
    (_, index) => <div key={`placeholder-${index}`} className="placeholder" />
  );

  const handleLoadMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
    const casesSection = document.getElementById("cases-section");
    if (casesSection) {
      casesSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="cases-container" id="cases-section">
      <section className="cases-section">
        <div className="cases-header">
          <h2 className="h2" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
        </div>

        <div className="category-buttons">
          {categories.map((category) => (
            <button
              key={category.name}
              className={`category-button ${selectedCategory === category.name ? "active" : ""
                }`}
              onClick={() =>
                !category.disabled &&
                setSelectedCategory(
                  selectedCategory === category.name ? null : category.name
                )
              }
              disabled={category.disabled}
            >
              {category.icon ? (
                <img
                  className="category-icon"
                  src={
                    selectedCategory === category.name
                      ? category.iconActive
                      : category.icon
                  }
                  alt={`${category.name} icon`}
                />
              ) : null}
              {category.name}
            </button>
          ))}
          <button className="category-button" disabled>
            Mehr demnächst
          </button>
        </div>

        <div className="cases-grid">
          {visibleCases.map((caseItem, index) => (
            <CaseCard
              key={index}
              title={caseItem.title}
              category={normalizeCategory(caseItem.category)}
              categories={categories}
              doctorName={caseItem.doctorName}
              doctorDescription={caseItem.doctorDescription}
              doctorProfile={caseItem.doctorProfile}
              image={caseItem.image}
              slug={caseItem.slug}
              categoryIcon={
                iconMapping[normalizeCategory(caseItem.category).toLowerCase()]?.icon
              }
            />
          ))}

          {!showAll && filteredCases.length > 11 && (
            <div className="placeholder load-more-placeholder">
              <button className="load-more-button" onClick={handleLoadMore}>
                Load More
              </button>
            </div>
          )}

          {showAll && placeholders}
        </div>

        {showAll && filteredCases.length > 11 && (
          <div className="show-less-container">
            <button className="show-less-button" onClick={handleShowLess}>
              Show Less
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default CasesSection;