// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/OpenSans-VariableFont_wdth,wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/PlayfairDisplay-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Open Sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: 100 900; /* Define the range of font weights available */
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans';
}

@font-face {
  font-family: 'Playfairdisplay';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Apply the font globally or to specific elements */
body {
  font-family: 'Open Sans';
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,+DAAkF;EAClF,oBAAoB,EAAE,+CAA+C;EACrE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,wBAAwB;EACxB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;EAC9B,+DAA0E;EAC1E,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,oDAAoD;AACpD;EACE,wBAAwB;AAC1B","sourcesContent":["@font-face {\n  font-family: 'Open Sans';\n  src: url('./assets/fonts/OpenSans-VariableFont_wdth\\,wght.ttf') format('truetype');\n  font-weight: 100 900; /* Define the range of font weights available */\n  font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Open Sans';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Open Sans';\n}\n\n@font-face {\n  font-family: 'Playfairdisplay';\n  src: url('./assets/fonts/PlayfairDisplay-SemiBold.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n/* Apply the font globally or to specific elements */\nbody {\n  font-family: 'Open Sans';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
