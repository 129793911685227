function saveUTMsToLocalStorage() {
  // console.log('storing utms')

  function getUrlParameter(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  const par_hcp = getUrlParameter("hcp_id");
  const par_source = getUrlParameter("utm_source");
  const par_medium = getUrlParameter("utm_medium");
  const par_campaign = getUrlParameter("utm_campaign");
  const par_content = getUrlParameter("utm_content");

  if (par_hcp || par_source || par_medium || par_campaign || par_content) {
    localStorage.setItem("hcp_id", par_hcp);
    localStorage.setItem("par_source", par_source);
    localStorage.setItem("par_medium", par_medium);
    localStorage.setItem("par_campaign", par_campaign);
    localStorage.setItem("par_content", par_content);
  }
};

export default saveUTMsToLocalStorage;