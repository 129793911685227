import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom'
import i18n from './i18n'
import { HelmetProvider } from 'react-helmet-async'
import { motion } from "framer-motion";

import LanguageSelector from './components/LanguageSelector'
import PolicyBanner from './components/PolicyBanner'
import saveUTMsToLocalStorage from './utils/saveUTMs'
import setRandomId from './utils/setRandomId'
import { UnlockedChaptersProvider } from './utils/UnlockedCases'
import userTrackingFS from './utils/userTrackingFS'

import './App.css'
import { AnalyticsProvider } from './utils/analyticsProvider'
import HomePageV2 from "./pages/HomePageV2";
import NotFound from './pages/NotFound'

const Homepage = lazy(() => import('./pages/Homepage'))
const Case = lazy(() => import('./pages/Case'))

// console.log('API URL:', process.env.REACT_APP_API_URL) // Debug line

// Apollo Client Setup with memory optimization
const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    largeDataField: {
                        merge: false,
                    },
                },
            },
        },
    }),
    request: (operation) => {
        const currentLanguage = i18n.language
        operation.setContext({
            headers: {
                'Accept-Language': currentLanguage,
            },
        })
    },
})

// Custom Hook to control scroll behavior based on route
const useNoScrollOnCasePage = () => {
    const location = useLocation()

    useEffect(() => {
        if (location.pathname.startsWith('/cases/')) {
            document.body.classList.add('no-scroll')
            document.body.style.overflow = "hidden";
        } else {
            document.body.classList.remove('no-scroll')
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.classList.remove('no-scroll')
        }
    }, [location])
}

// Function to check for 'hcp_id' in URL and localStorage
const hasHcpId = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.has('hcp_id') || localStorage.getItem('hcp_id')
}

// Scroll Handler Component that uses the custom hook
function CaseScrollHandler({ children }) {
    useNoScrollOnCasePage()
    return <>{children}</>
}

const App = () => {
    const { i18n } = useTranslation()
    const [pageloadSubmitted, setPageload] = useState(false)

    saveUTMsToLocalStorage() // Track UTMs in local storage

    const isInitialRender = useRef(true)

    setRandomId('pageLoaded')

    // Track page load event
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false
            return
        }

        if (!pageloadSubmitted) {
            setPageload(true)
            userTrackingFS('pageLoaded')
        }
    }, [pageloadSubmitted])

    return (
        <HelmetProvider>
            <Router>
                <ApolloProvider client={client}>
                    <UnlockedChaptersProvider>
                        <CaseScrollHandler>
                            <div className="App">
                                <LanguageSelector i18n={i18n} />
                                {hasHcpId() && <PolicyBanner />} {/* Conditionally render PolicyBanner */}

                                <Suspense fallback={<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>Loading...</motion.div>}>
                                    <Routes>
                                        <Route path="/" element={<HomePageV2 />} />
                                        <Route path="/cases/:slug" element={<Case />} />
                                        <Route path="*" element={<NotFound />} /> 
                                    </Routes>
                                </Suspense>
                            </div>
                        </CaseScrollHandler>
                    </UnlockedChaptersProvider>
                </ApolloProvider>
            </Router>
        </HelmetProvider>
    );
};

export default App