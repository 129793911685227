import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Parallax, Autoplay } from "swiper/modules";
import "./style.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import iconOncology from "../../assets/icons/oncology.svg";
import iconOncologyActive from "../../assets/icons/oncology-active.svg";
import iconObesity from "../../assets/icons/obesity.svg";
import iconObesityActive from "../../assets/icons/obesity-active.svg";
import iconHematology from "../../assets/icons/hematology.svg";
import iconHematologyActive from "../../assets/icons/hematology-active.svg";
import iconDermatology from "../../assets/icons/dermatology.svg";
import iconDermatologyActive from "../../assets/icons/dermatology-active.svg";
import iconDiabetes from "../../assets/icons/diabetes.svg";
import iconDiabetesActive from "../../assets/icons/diabetes-active.svg";
import { Link } from "react-router-dom";

// Normalized iconMapping
const iconMapping = {
  onkologie: { icon: iconOncology, iconActive: iconOncologyActive },
  hämatologie: { icon: iconHematology, iconActive: iconHematologyActive },
  adipositas: { icon: iconObesity, iconActive: iconObesityActive },
  diabetes: { icon: iconDiabetes, iconActive: iconDiabetesActive },
  dermatologie: { icon: iconDermatology, iconActive: iconDermatologyActive },
};

// Map Strapi categories to their intended display names
const categoryMapping = {
  Haematologie: "Hämatologie",
  hamatologie: "Hämatologie",
  Onkologie: "Onkologie",
  Adipositas: "Adipositas",
  Diabetes: "Diabetes",
  Dermatologie: "Dermatologie",
};

const normalizeCategory = (category) => {
  return categoryMapping[category] || category; // Normalize categories
};

const normalizeUtmContent = (utmContent) => {
  if (!utmContent) return null; // Prevents calling toLowerCase on null

  const utmMapping = {
    hamatologie: "Hämatologie",
    onkologie: "Onkologie",
    adipositas: "Adipositas",
    diabetes: "Diabetes",
    dermatologie: "Dermatologie",
  };

  const matchedUtm = Object.keys(utmMapping).find((key) =>
    utmContent.toLowerCase().includes(key)
  );

  return matchedUtm ? utmMapping[matchedUtm] : null; // Return null if no match
};


const CaseCarousel = ({ cases }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const utmContentParam = params.get("utm_content");
  
    const normalizedUtmContent = normalizeUtmContent(utmContentParam);
    setSelectedCategory(normalizedUtmContent); // Explicitly set to null if no match
  }, []);  

  const filteredCases = selectedCategory
    ? cases.filter(
      (caseItem) =>
        normalizeCategory(caseItem.category) === selectedCategory
    )
    : cases;

  return (
    <Swiper
      effect="coverflow"
      grabCursor={true}
      modules={[Navigation, Pagination, Parallax, Autoplay]}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className} swiper-pagination-bullet"></span>`;
        },
      }}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        speed: 3000,
        runCallbacks: true,
        waitForTransition: true,
        easing: "linear",
      }}
      className="mySwiper"
    >
      {filteredCases.map((caseItem, index) => {
        const normalizedCategory = normalizeCategory(caseItem.category);
        const categoryIcons = iconMapping[normalizedCategory.toLowerCase()] || {};
        const isActive = selectedCategory === normalizedCategory;

        return (
          <SwiperSlide key={index}>
            <div
              className="CarouselCase"
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 154.15%),url('${caseItem.image}')`,
              }}
            >
              <div className="case-category">
                {categoryIcons.icon && (
                  <img
                    className="category-icon"
                    src={isActive ? categoryIcons.iconActive : categoryIcons.icon}
                    alt={normalizedCategory}
                  />
                )}
                {normalizedCategory}
              </div>
              <p className="case-title" dangerouslySetInnerHTML={{ __html: caseItem.title }} />
              <div className="case-cta">
                <div className="doctor-block">
                  <img
                    src={caseItem.doctorProfile}
                    className="doctor-image"
                    alt={caseItem.doctorName}
                  />
                  <div className="doctor-box">
                    <p className="doctor-name">{caseItem.doctorName}</p>
                    <p className="doctor-description">
                      {caseItem.doctorDescription}
                    </p>
                  </div>
                </div>
                <Link to={`/cases/${caseItem.slug}`} className="button buttonNew">
                  Jetzt mitmachen
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_980_143)">
                      <rect
                        x="0.313477"
                        y="0.612961"
                        width="25"
                        height="25"
                        rx="12.5"
                        fill="white"
                      />
                      <path
                        d="M17.4863 9.60796C17.2711 9.60796 17.0968 9.78223 17.0968 9.99741V12.6311L13.0422 9.68248C12.9234 9.59631 12.7666 9.58415 12.6362 9.65035C12.5057 9.71705 12.4234 9.85092 12.4234 9.99746V12.6317L8.36825 9.68253C8.24996 9.59636 8.09272 9.58419 7.96225 9.6504C7.83226 9.71709 7.75 9.85097 7.75 9.99751V16.2288C7.75 16.3753 7.83227 16.5092 7.96273 16.5759C8.01872 16.6041 8.07908 16.6182 8.13945 16.6182C8.22026 16.6182 8.30059 16.5929 8.36825 16.5437L12.4234 13.5946V16.2288C12.4234 16.3753 12.5057 16.5092 12.6362 16.5759C12.6921 16.6041 12.7525 16.6183 12.8129 16.6183C12.8937 16.6183 12.974 16.5929 13.0422 16.5438L17.0968 13.5947V16.2288C17.0968 16.444 17.2711 16.6183 17.4863 16.6183C17.7015 16.6183 17.8757 16.444 17.8757 16.2288V9.9976C17.8757 9.78242 17.7015 9.60815 17.4863 9.60815V9.60796Z"
                        fill="#1A8693"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_980_143">
                        <rect
                          x="0.313477"
                          y="0.612961"
                          width="25"
                          height="25"
                          rx="12.5"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CaseCarousel;
