// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background-color: #0056b3;
    color: white;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .logo img {
    max-height: 50px;
  }
  
  .nav {
    display: flex;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  
  .nav-list li a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .nav-list li a:hover {
    color: #ffdd57;
  }
  
  .cta-button {
    background-color: #ffdd57;
    border: none;
    color: #0056b3;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #ffd333;
  }
  `, "",{"version":3,"sources":["webpack://./src/homeComponents/Header/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,MAAM;IACN,aAAa;IACb,wCAAwC;EAC1C;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,WAAW;IACX,SAAS;IACT,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".header {\n    background-color: #0056b3;\n    color: white;\n    padding: 1rem 2rem;\n    position: sticky;\n    top: 0;\n    z-index: 1000;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  }\n  \n  .header-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .logo img {\n    max-height: 50px;\n  }\n  \n  .nav {\n    display: flex;\n  }\n  \n  .nav-list {\n    display: flex;\n    list-style: none;\n    gap: 1.5rem;\n    margin: 0;\n    padding: 0;\n    align-items: center;\n  }\n  \n  .nav-list li a {\n    text-decoration: none;\n    color: white;\n    font-size: 1rem;\n    transition: color 0.3s;\n  }\n  \n  .nav-list li a:hover {\n    color: #ffdd57;\n  }\n  \n  .cta-button {\n    background-color: #ffdd57;\n    border: none;\n    color: #0056b3;\n    padding: 0.5rem 1rem;\n    font-size: 1rem;\n    font-weight: bold;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .cta-button:hover {\n    background-color: #ffd333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
