function saveUTMsToLocalStorage() {
  // Helper function to get a parameter from the URL
  function getUrlParameter(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  // Fetch URL parameters
  const par_hcp = getUrlParameter("hcp_id");
  const par_source = getUrlParameter("utm_source");
  const par_medium = getUrlParameter("utm_medium");
  const par_campaign = getUrlParameter("utm_campaign");
  const par_content = getUrlParameter("utm_content");

  // Save only filled parameters to localStorage
  if (par_hcp) localStorage.setItem("hcp_id", par_hcp);
  if (par_source) localStorage.setItem("par_source", par_source);
  if (par_medium) localStorage.setItem("par_medium", par_medium);
  if (par_campaign) localStorage.setItem("par_campaign", par_campaign);
  if (par_content) localStorage.setItem("par_content", par_content);
}

export default saveUTMsToLocalStorage;
