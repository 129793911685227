import { createContext, useContext, useReducer } from 'react';
import userTrackingFS from '../utils/userTrackingFS';

const UnlockedChapters = createContext(null);

const unlockedChaptersReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_CHAPTER': {
            const { caseId, chapterId } = action.payload;

            // Get existing unlocked chapters for the case
            const unlockedForThisCase = state[caseId] || [];

            // Add the new chapter if it's not already in the array
            const updatedChapters = unlockedForThisCase.includes(chapterId)
                ? unlockedForThisCase
                : [...unlockedForThisCase, chapterId];

            // Log the highest chapter for debug purposes
            const highestChapter = Math.max(...updatedChapters);
            console.log(`Highest unlocked chapter for case ${caseId}:`, highestChapter);

            return {
                ...state,
                [caseId]: updatedChapters, // Update the unlocked chapters for the case
            };
        }
        case 'RESET_CHAPTERS': {
            const { caseId } = action.payload;
            return {
                ...state,
                [caseId]: [], // Reset chapters for the specific case
            };
        }
        default:
            return state;
    }
};



const initialState = {};

export function UnlockedChaptersProvider({ children }) {
    const [unlockedChapters, dispatch] = useReducer(unlockedChaptersReducer, initialState);

    return (
        <UnlockedChapters.Provider value={{ unlockedChapters, dispatch }}>
            {children}
        </UnlockedChapters.Provider>
    );
}

export const useUnlockedChapters = () => useContext(UnlockedChapters);
